import { ElementType, Fragment, useContext } from 'react';

import { IntlContext } from '@providers/IntlProvider';

type Props = {
  tagName?: ElementType;
  numeric?: string;
  unit?: Intl.RelativeTimeFormatUnit;
  value: number;
  fallbackDate?: string;
} & Intl.RelativeTimeFormatOptions;

const FormattedRelativeTime = ({
  tagName: Component = Fragment,
  numeric = 'auto',
  localeMatcher,
  style,
  unit = 'day',
  value,
  fallbackDate,
}: Props): JSX.Element => {
  const { intl } = useContext(IntlContext);
  return <Component>{intl.formatRelativeTime(value, unit, { numeric, localeMatcher, style }, fallbackDate)}</Component>;
};

export default FormattedRelativeTime;
