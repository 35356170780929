import isSameDay from 'date-fns/isSameDay';

import { Article } from 'types/models/Article';

const getArticleDate = ({
  createdAt,
  publishedAt,
  updatedAt,
}: Partial<Pick<Article, 'createdAt' | 'updatedAt' | 'publishedAt'>>) => {
  const isSameDayDate = createdAt && updatedAt && isSameDay(new Date(publishedAt || createdAt), new Date(updatedAt));
  const updatedAtDate = updatedAt && new Date(updatedAt);
  const publishedAtDate = publishedAt && new Date(publishedAt);

  const isUpdated = !!updatedAtDate && !isSameDayDate && (!publishedAtDate || updatedAtDate > publishedAtDate);
  const lastDateTimestamp = Math.max(Number(updatedAtDate || 0), Number(publishedAtDate || 0));

  return { isUpdated, lastDateTimestamp };
};

export default getArticleDate;
