import config from 'config';
import { ArticlePreview as ArticlePreviewType } from 'types/models/Article';
import ArticleInfo from '@components/ArticleInfo';
import ArticleDate from '@components/ArticleDate';
import Teaser, { Props as TeaserProps } from '@components/Teaser';
import { FormattedMessage } from 'utils/intl';
import getArticleDate from 'utils/getArticleDate';

import messages from './ArticlePreivew.messages';

const { routes } = config;

type Props = Omit<TeaserProps, 'topContent' | 'bottomContent' | 'href'> & {
  tags: ArticlePreviewType['tags'];
  slug: string;
  createdAt: string;
  updatedAt: string;
  publishedAt?: string;
};

const ArticlePreview = ({
  createdAt,
  updatedAt,
  publishedAt,
  slug,
  withFakeLink,
  tags,
  ...props
}: Props): JSX.Element => {
  const href = { pathname: routes.article.name.href, query: { name: slug } };
  const { isUpdated, lastDateTimestamp } = getArticleDate({ createdAt, updatedAt, publishedAt });

  return (
    <Teaser
      {...props}
      href={href}
      withFakeLink={withFakeLink}
      topContent={
        <ArticleDate
          date={lastDateTimestamp ? new Date(lastDateTimestamp) : createdAt}
          label={<FormattedMessage {...(isUpdated ? messages.updated : messages.created)} />}
        />
      }
      bottomContent={<ArticleInfo variant="short" tags={tags} withFakeLink={withFakeLink} />}
    />
  );
};

export default ArticlePreview;
