import classNames from 'classnames';

import config from 'config';
import { Article } from 'types/models/Article';
import Button from '@components/atoms/Button';
import Typography from '@components/atoms/Typography';
import ArticleDate from '@components/ArticleDate';
import SeoShare from '@components/SeoShare';
import { FormattedMessage } from 'utils/intl';
import getArticleDate from 'utils/getArticleDate';

import classes from './ArticleInfo.module.scss';
import messages from './ArticleInfo.messages';

const { routes } = config;

type Props = Partial<Pick<Article, 'tags' | 'createdAt' | 'updatedAt' | 'publishedAt'>> & {
  className?: string;
  variant: 'short' | 'long';
  withFakeLink?: boolean;
} & (
    | ({
        withShare?: false;
      } & Partial<Pick<Article, 'slug' | 'seoData'>>)
    | ({
        withShare: true;
      } & Pick<Article, 'slug' | 'seoData'>)
  );

const ArticleInfo = ({
  className,
  tags = [],
  createdAt,
  updatedAt,
  publishedAt,
  withShare,
  seoData,
  slug,
  variant,
  withFakeLink,
}: Props): JSX.Element => {
  const { isUpdated, lastDateTimestamp } = getArticleDate({ createdAt, updatedAt, publishedAt });

  return (
    <div className={classNames(classes.tags, className)}>
      {tags.map(({ articleTag }) => (
        <Button
          key={articleTag.id}
          variant="tag"
          size="sm"
          className={classes.tag}
          component={withFakeLink ? 'button' : undefined}
          href={{
            pathname: routes.articles.category.tag.href,
            query: { category: articleTag.category.slug, tag: articleTag.slug },
          }}
        >
          <Typography variant="caption1" renderAs="span">
            {articleTag.name}
          </Typography>
        </Button>
      ))}
      {isUpdated && (
        <ArticleDate
          className={classes.time}
          date={new Date(lastDateTimestamp)}
          label={<FormattedMessage {...messages.updated} />}
        />
      )}
      {(variant === 'long' || !isUpdated) && !!createdAt && (
        <ArticleDate
          className={classes.time}
          date={publishedAt || createdAt}
          label={variant === 'long' ? <FormattedMessage {...messages.created} /> : undefined}
        />
      )}
      {withShare && <SeoShare href={routes.article.name.href} seoData={seoData} slug={slug} />}
    </div>
  );
};

export default ArticleInfo;
