import { forwardRef, ReactNode, HTMLAttributes, ElementType } from 'react';
import classNames from 'classnames';

import Message from 'types/Message';
import { Classes } from 'types/Classes';
import SectionHeader from '@components/SectionHeader';

import classes from './Section.module.scss';

type Props = {
  id?: string;
  title?: Message;
  classes?: Classes<'root' | 'header' | 'title'>;
  headerComponent?: ElementType;
  children: ReactNode;
  actions?: ReactNode;
  style?: HTMLAttributes<HTMLElement>['style'];
};

const Section = forwardRef<HTMLElement, Props>(
  ({ id, title, actions, classes: customClasses, headerComponent, style, children }: Props, ref): JSX.Element => (
    <section id={id} ref={ref} className={classNames(classes.wrapper, customClasses?.root)} style={style}>
      {!!title && (
        <SectionHeader
          classes={{ ...customClasses, header: classNames(classes.header, customClasses?.header) }}
          renderAs={headerComponent}
          actions={actions}
        >
          {title}
        </SectionHeader>
      )}
      {children}
    </section>
  )
);

export default Section;
