import { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import { Description as DescriptionType } from 'types/Description';
import { SeoData } from 'types/models/SeoData';
import Message from 'types/Message';
import ImageFile from 'types/ImageFile';
import Url from 'types/Url';
import Description from '@templates/Description';
import ContentBox from '@components/atoms/ContentBox';
import Link from '@components/atoms/Link';
import Typography from '@components/atoms/Typography';
import toLowerCase from 'utils/toLowerCase';

import classes from './Teaser.module.scss';

export type Props = {
  href: Url;
  seoData?: SeoData;
  teaser: DescriptionType;
  image: ImageFile;
  imageFit: Uppercase<Exclude<CSSProperties['objectFit'], undefined>>;
  variant: 'small' | 'big';
  orientation?: 'vertical' | 'horizontal';
  titleLines?: number;
  descriptionLines?: number;
  imageText?: Message;
  topContent?: ReactNode;
  bottomContent?: ReactNode;
  raw?: boolean;
  withFakeLink?: boolean;
};

const Teaser = ({
  href,
  seoData,
  teaser,
  topContent,
  bottomContent,
  image,
  imageText,
  titleLines,
  descriptionLines = 3,
  variant,
  orientation = variant === 'big' ? 'horizontal' : 'vertical',
  imageFit,
  raw,
  withFakeLink,
}: Props): JSX.Element => {
  const isBig = variant === 'big';

  return (
    <div className={classNames(classes.wrapper, classes[variant], classes[orientation])}>
      <div className={classes.header}>
        {topContent}
        <Link component={withFakeLink ? 'span' : undefined} href={href} underline="hover">
          <Typography
            variant="h2"
            weight="700"
            className={classNames(classes.title, { [classes.bigTitle]: isBig, [classes.smallTitle]: !isBig })}
            title={seoData?.header}
            renderAs={isBig ? 'h2' : 'div'}
            ellipsisLines={titleLines}
          >
            {seoData?.header}
          </Typography>
        </Link>
      </div>
      <Link component={withFakeLink ? 'span' : undefined} href={href} className={classes.imageLink}>
        <ContentBox
          className={classNames(classes.image, { [classes.cover]: imageFit === 'COVER' })}
          variant="image"
          alt="article thumbnail"
          objectFit={toLowerCase(imageFit)}
          resolveConfig={{
            preset: isBig ? 'article_thumbnail_main' : 'article_thumbnail',
            extension: image.extension,
            name: image.basename,
            productName: 'article',
            description: 'thumbnail',
            width: image.width,
            height: image.height,
          }}
          hasLazyLoad={!raw && !isBig}
          hasPreload={!raw && isBig}
        />
        {!!imageText && isBig && (
          <Typography variant="h2" renderAs="div" className={classes.imageText}>
            {imageText}
          </Typography>
        )}
      </Link>
      <div>
        <Typography variant="body1" renderAs="div" ellipsisLines={descriptionLines} className={classes.description}>
          <Description className={classes.teaser} data={teaser} />
        </Typography>
        {!!bottomContent && <div className={classes.bottomContent}>{bottomContent}</div>}
      </div>
    </div>
  );
};

export default Teaser;
