import classNames from 'classnames';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';

import Message from 'types/Message';
import Typography from '@components/atoms/Typography';
import FormattedRelativeTime from '@components/FormattedRelativeTime';

import classes from './ArticleDate.module.scss';

type Props = {
  label?: Message;
  date: string | Date;
  className?: string;
};

const getRelativeTime = (days: number): { value: number; unit: Intl.RelativeTimeFormatUnit } => {
  if (days <= -365) {
    return {
      value: Math.floor(days / 365),
      unit: 'year',
    };
  }
  if (days <= -31) {
    return {
      value: Math.floor(days / 31),
      unit: 'month',
    };
  }
  return {
    value: days,
    unit: 'day',
  };
};

const ArticleDate = ({ className, label, date }: Props): JSX.Element => {
  const days = differenceInCalendarDays(new Date(date), new Date());

  return (
    <Typography
      variant="caption1"
      renderAs="span"
      className={classNames(classes.time, className)}
      title={format(new Date(date), 'dd.MM.yyyy')}
    >
      {label} <FormattedRelativeTime numeric="auto" {...getRelativeTime(days)} />
    </Typography>
  );
};

export default ArticleDate;
